/* --------------------------------------------------------------------------
  Enables the native <details> element to auto-close when users click
  outside of it. Works by removing the `open` attribute.

  NOTE: I have not tested how performant this `stimulus-use` library is.
  Could potentially be a performance issue with lots of such <details>.
-------------------------------------------------------------------------- */
import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

// HEXIS FRONTEND
import $console from '@hexis-hr/utils/console'


/* stimulusFetch: 'lazy' */
export default class extends Controller<HTMLElement> {

  /* --------------------------------------------------------------------------
    CONTROLLER LIFECYCLE HOOKS
    Try to keep this short and clean it should just "tell a story".
    Most of the code should be written in meaningfully named methods below.
  -------------------------------------------------------------------------- */

  // (1) Once, when the controller is first instantiated
  initialize() { }

  // (2) Anytime the controller is connected to the DOM
  connect() {
    useClickOutside(this)
  }

  // (3) Anytime the controller is disconnected from the DOM
  disconnect() { }


  /* --------------------------------------------------------------------------
    EVENTS
  -------------------------------------------------------------------------- */
  clickOutside(event: Event) {
    this.element.removeAttribute('open')
  }


}
